/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { Link } from "gatsby"

export default ({ attorney }) => (
  <div
    sx={{
      bg: 'gray.2',
      borderRadius: '0 0 50px 0',
      boxShadow: 'lg',
      p: 4,
      pt: 6,
      textAlign: 'center',
      position: 'relative',
      my: 5
    }}>
    <img
      src={attorney.profile.publicURL}
      alt={attorney.name}
      sx={{
        position: 'absolute',
        borderRadius: 'full',
        width: '180px',
        boxShadow: 'lg',
        top: '-70px',
        left: '50%',
        ml: '-90px'
      }} />
    <Styled.h4
      sx={{
        fontSize: 3,
        m: 0,
        color: 'secondary'
      }}>{attorney.name}
    </Styled.h4>
    <p
      sx={{
        mt: 4
      }}>{attorney.bio.substring(0, 100) + '...'}</p>
    <Link
      to={`/attorney/${attorney.slug}`}
      sx={{
        bg: 'primary',
        borderStyle: 'solid',
        borderColor: 'primary',
        borderWidth: 1,
        color: 'white',
        px: 4,
        py: 1,
        mt: 4,
        display: 'inline-block',
        fontFamily: 'heading',
        textTransform: 'uppercase',
        fontSize: 0
      }}>
      View Profile
    </Link>
  </div>
)