/** @jsx jsx */
import { jsx, Styled, Container, Flex, Box } from "theme-ui"
import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import AttorneyCard from "../components/attorneyCard"

const ServicePage = ({ data: { service, allService } }) => {
  return (
    <Layout>
      <Box
        sx={{
          backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.3) 100%), url("${service.header.publicURL}")`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          py: 5
        }}>
        <Container>
          <Flex
            sx={{
              alignItems: 'center',
              px: 4
            }}>
            <Box
              sx={{
                position: 'relative',
                mr: 3
              }}>
              <div
                sx={{
                  p: 4,
                  borderRadius: 'full',
                  bg: 'primary'
                }}>
                <img
                  src={service.icon.publicURL}
                  alt={service.name}
                  sx={{
                    width: '55px',
                  }} />
              </div>
            </Box>
            <Flex
              sx={{
                flexDirection: 'column'
              }}>
              <Styled.h1
                sx={{
                  color: 'white',
                  mb: 2
                }}>
                {service.name}
              </Styled.h1>
              <Box
                sx={{
                  color: 'white'
                }}>
                {service.specialties.map((specialty, i) => (
                  <React.Fragment key={i}>
                    <span
                      sx={{
                        mr: 2
                      }}>{specialty}</span>
                    {
                      i !== service.specialties.length - 1 && (
                        <span sx={{
                          mr: 2
                        }}> / </span>
                      )
                    }
                  </React.Fragment>
                ))}
              </Box>
            </Flex>
          </Flex>
        </Container>
      </Box>
      <Box
        as="section"
        sx={{
          py: 5
        }}>
        <Container>
          <Flex
            sx={{
              flexWrap: 'wrap'
            }}>
            <Box
              sx={{
                width: ['full', '1/3', '1/4'],
                pr: 2
              }}>
              <Flex
                as="aside"
                sx={{
                  flexDirection: 'column',
                  mb: 4
                }}>
                <Styled.h3 sx={{
                  mb: 4
                }}>Practice Areas</Styled.h3>
                <Flex
                  sx={{
                    flexDirection: 'column',
                    p: 0,
                    m: 0,
                    listStyle: 'none'
                  }}
                  as="ul">
                  {allService.nodes.map(node => (
                    <li key={node.id}>
                      {node.slug === service.slug ? (
                        <div
                          sx={{
                            p: 2,
                            bg: 'gray.3',
                            borderStyle: 'solid',
                            borderColor: 'gray.2',
                            borderWidth: '0 0 1px'
                          }}>{node.name}</div>
                      ) : (
                          <Link
                            sx={{
                              display: 'block',
                              p: 2,
                              borderStyle: 'solid',
                              borderColor: 'gray.2',
                              borderWidth: '0 0 1px'
                            }}
                            to={`/${node.slug}`}>
                            {node.name}
                          </Link>
                        )}

                    </li>
                  ))}
                </Flex>
              </Flex>
              {service.attorneys && (
                <Flex
                  as="aside"
                  sx={{
                    flexDirection: 'column',
                  }}>
                  <Styled.h3 sx={{
                    mb: 0
                  }}>Attorneys</Styled.h3>
                  <Styled.h5 sx={{
                    fontSize: 1,
                    fontWeight: 200,
                    mb: 5
                  }}> - {service.name}</Styled.h5>
                  {service.attorneys.map(attorney => (
                    <AttorneyCard attorney={attorney} key={attorney.id} />
                  ))}
                </Flex>
              )}
            </Box>
            <Box
              sx={{
                width: ['full', '7/12', '2/3'],
                ml: 'auto'
              }}>
              <div dangerouslySetInnerHTML={{ __html: service.content.html }}></div>
            </Box>
          </Flex>
        </Container>
      </Box>
    </Layout >
  )
}

export default ServicePage

export const query = graphql`
  query ($id: String!) {
    service(id: { eq: $id }){
      name
      slug
      description
      icon{
        publicURL
      }
      header {
        publicURL
      }
      specialties
      content {
        html
      }
      attorneys {
        id
        name
        slug
        profile {
          publicURL
        }
        bio
      }
    }
    allService {
      nodes {
        id
        name
        slug
        icon {
          publicURL
        }
      }
    }
  }
`
